<!--  -->
<template>
    <div class='MonthlyReport'>
        <el-row class="info">
            <el-col :span="4"><span style="text-align: center; display:inline-block; font-size: 16px;">
                    姓名：{{ info.realName }}</span></el-col>
            <el-col :span="4"><span style="text-align: center; display:inline-block; font-size: 16px;">
                    性别：{{ info.gender === 1 ? '男' : '女' }}</span></el-col>
            <el-col :span="4"><span style="text-align: center; display:inline-block; font-size: 16px;">
                    年龄：{{ info.age }}</span></el-col>
            <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                    时间：{{ info.startTime + '——' + info.endTime }} </span></el-col>
            <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                    基础病信息：{{ info.disease }} </span></el-col>
        </el-row>
        <br>
        <!-- 提示区域 -->
        <!-- <el-alert title="创建当月报告" type="info" center show-icon :closable="false"></el-alert> -->

        <!-- 步骤条区域 -->
        <el-steps :space="200" :active="stepIndex - 0" finish-status="success" align-center>
            <el-step title="基本信息"></el-step>
            <el-step title="异常复核"></el-step>
            <el-step title="结论撰写"></el-step>
            <el-step title="预览"></el-step>
            <el-step title="完成"></el-step>
        </el-steps>

        <!-- tab栏区域 -->
        <el-tabs :tab-position="'left'" v-model="tabIndex">
            <el-tab-pane label="基本概况" name='0'>
                <el-row>
                    <el-col :span="12">
                        本月共测量
                        <div class="item" v-for="item in GeneralInfo" :key="item.title">

                            <span class="title">{{ item.title }}</span>
                            <span class="number">
                                {{ item.number + item.unit }}
                            </span>
                        </div>
                    </el-col>
                <!-- <el-col :span="12">
                        在心脏监护过程中，心狗云医检测到以下异常：
                        <div>
                            <el-table :data="AbnormalList">
                                <el-table-column label="名称" prop="disease" sortable> </el-table-column>
                                <el-table-column label="日期" prop="date" sortable> </el-table-column>
                                <el-table-column label="复核状态" prop="status" sortable> </el-table-column>
                            </el-table>
                        </div>
                                                                                                                                                                                    </el-col> -->
                </el-row>
                <el-row>
                    <el-button type="primary" @click="FinishPsinfo()">下一步</el-button>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="异常复核" name='1'>
                <el-row>
                    <el-table :default-sort="{ prop: 'createTime', order: 'descending' }" :data="checkListData">
                        <!-- <el-table-column label="用户" prop="uid"></el-table-column> -->
                        <el-table-column label="类型" prop="type" sortable>
                            <template slot-scope="scope">
                                <!-- 待补充其他type对应名字 -->
                                {{ scope.row.type === 'static_ecg_vip' ? '静态单导联' : 'dynamic_ecg_vip' ? '动态单导联' :
                                    'static_ecg8_vip' ?
                                        '静态八导联' : '动态八导联' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" prop="createTime" sortable></el-table-column>
                        <el-table-column label="诊断结论" prop="xingouConclusion"></el-table-column>

                        <el-table-column label="时长(秒)" prop="totalTime"> </el-table-column>
                        <el-table-column label="查看记录" prop="record">
                            <template v-slot="scope">
                                <el-button @click="click2Content(scope.row)" type="primary">查看详情</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column prop="status" label="复核状态" sortable
                            :filters="[{ text: '已复核', value: 1 }, { text: '待复核', value: 0 }]" :filter-method="filterTag"
                            filter-placement="bottom-end">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.status === 0 ? 'warning' : 'success'" disable-transitions
                                    style="font-size: 14px;">{{ scope.row.status === 0 ? '待复核' : '已复核' }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="复核时间" prop="checkTime" sortable></el-table-column>

                    </el-table>
                </el-row>
                <el-row>
                    <el-button type="primary" @click="FinishAnomalyCheck()">下一步</el-button>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="结论撰写" name='2'>
                <el-row>
                <!-- <el-col :span="12">
                        统计图表
                                                                                                                                                                                </el-col> -->
                    <el-col :span="12">
                        <div class="sub-title">1. 请解释检测到的异常</div>
                        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容"
                            v-model="conclusionExplanation">
                        </el-input>
                        <div class="sub-title">2. 诊疗及生活建议</div>
                        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容"
                            v-model="conclusionCure">
                        </el-input>
                    <!-- <el-autocomplete class="inline-input" v-model="state1" :fetch-suggestions="querySearch"
                                                                                                                                                                                        placeholder="请输入内容" @select="handleSelect"></el-autocomplete> -->
                    </el-col>
                </el-row>
                <el-row>
                    <el-button type="primary" @click="FinishClonclusion()">提交结论</el-button>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="预览" name='3'>
                <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
                    <div style="margin:45px 57px">
                        <el-row class="title">
                            <el-col :span="6">
                                <div class="blank"></div>
                            </el-col>
                            <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                                    心狗VIP心脏监护报告
                                </span></el-col>
                            <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                                    src="../../../../public/img/logo_xingou.png" /></el-col>
                        </el-row>
                        <!-- 绘制双横线 -->
                        <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
                        <el-row>
                            <div align="center" style="font-size: 20px; ">
                                VIP基本信息
                            </div>
                        </el-row>
                        <el-row class="info">
                            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                    姓名：{{ info.realName }}</span></el-col>
                            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                    性别：{{ report.gender === 1 || report.gender === '1' ? '男' : '女' }}</span></el-col>
                            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                    年龄：{{ info.age }}</span></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                    时间：{{ info.startTime + '——' + info.endTime }} </span></el-col>
                            <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                    基础病信息：{{ info.disease }} </span></el-col>
                        </el-row>
                        <!-- 绘制单横线 -->
                        <hr style="border-top: 2px solid gray;" />
                        <div align="center" style="font-size: 20px; ">
                            分析结果统计
                        </div>
                        <div>
                            <el-descriptions title="基本概况" :column="1" border>
                                <el-descriptions-item v-for="item in GeneralInfo" :key="item.title" :label="item.title">
                                    {{ item.number + item.unit }}
                                </el-descriptions-item>
                            </el-descriptions>
                            <br>
                            <div>在心脏监护过程中，心狗云医检测到以下结论：</div>
                            <el-table :data="AbnormalList" @cell-dblclick="cellDblClick" :show-header=false
                                :row-style="{ height: '30px' }" :cell-style="{ padding: '0' }">
                                <el-table-column type="index"></el-table-column>
                                <el-table-column prop="disease" label="结论">
                                    <template slot-scope="scope">
                                        <span v-if="!scope.row.isEditCell" style="cursor:pointer">{{ scope.row.disease
                                        }}
                                        </span>
                                        <el-input v-if="scope.row.isEditCell" v-model="scope.row.disease" placeholder="结论"
                                            @blur="cellBlur(scope.row, scope.column)" style="width:70%"
                                            ref="DiseaseRef"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Actions" width="120px">
                                    <template slot-scope="scope">
                                        <el-button type="danger" icon="el-icon-delete" size="mini"
                                            @click="deleteRow(scope.$index)">
                                        </el-button>
                                    </template>
                                </el-table-column>

                                <!-- <el-table-column label="日期" prop="date" sortable> </el-table-column> -->
                                <!-- <el-table-column label="复核状态" prop="status" sortable> </el-table-column> -->
                            </el-table>

                            <el-descriptions title="医生结论" :column="1" border>
                                <el-descriptions-item label="解释检测到的异常">
                                    {{ report.conclusionExplanation }}
                                </el-descriptions-item>
                                <el-descriptions-item label="诊疗及生活建议">
                                    {{ report.conclusionCure }}
                                </el-descriptions-item>
                            </el-descriptions>
                            <br>
                            <div align="right">
                                签字：
                                <el-button type="primary" plain icon="el-icon-edit"
                                    @click="makeSignature()">添加签名</el-button>
                                <img style="width:30%" :src="Signature" alt="" v-if="Signature">
                            </div>
                            <div align="right">
                                日期：{{ ViewReport.createTime }}
                            </div>
                            <div align="right" style="font-size: 10px;">
                                *医生建议及诊断结果仅供参考
                            </div>
                        </div>


                    </div>
                </div>
                <el-row>
                    <el-button type="primary" plain @click="updateReport()">更新</el-button>
                    <el-button type="primary" @click="FinishView()">完成</el-button>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="手写签名" :visible.sync="SignatureVisible" width="600px" :before-close="handleClose">
            <VueSignaturePad width="500px" height="200px" ref="signaturePad" />
            <div>
                <el-button @click="save()">保存</el-button>
                <el-button @click="undo()">撤销</el-button>
            </div>
        </el-dialog>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
    //import引入的组件需要注入到对象中才能使用
    data() {
        //这里存放数据
        return {
            stepIndex: '0',
            tabIndex: '0',
            info: {

            },
            usageCount: {

            },
            GeneralInfo: [
                {
                    title: "静态20s心电:",
                    number: 0,
                    unit: '次'
                },
                {
                    title: "动态测量:",
                    number: 0,
                    unit: '次'
                },
                {
                    title: "心脏监护覆盖天数:",
                    number: 0,
                    unit: '天'
                },
                {
                    title: "时长共计:",
                    number: 0,
                    mounted: function () {
                        this.number = this.usageCount.staticEcg
                    },
                    unit: '分钟'
                },
                {
                    title: "定期监护习惯:",
                    number: 0,
                    mounted: function () {
                        this.number = this.usageCount.staticEcg
                    },
                    unit: ''
                },
            ],
            AbnormalList: [
            ],
            tableData: [
                {
                    hitch: '疾病1'

                },
                {
                    hitch: '疾病2'
                },
            ],
            checkListData: [],
            conclusionExplanation: '',
            conclusionCure: '',
            report: {

            },
            ViewReport: {

            },
            Signature: '',
            SignatureVisible: false,
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        filterTag(value, row) {
            return row.checkStatus === value;
        },
        beforeTabLeave(activeName, oldActiveName) {
            if (oldActiveName === '0') {
                this.$message.error('请先完成上一步')
                return false
            }
        },
        // 本月时间
        TimePeriod() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 30);
            this.today = this.TimeProcess(today)
            this.formerday = this.TimeProcess(formerday)
        },
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return MM + '-' + d
        },
        // 接口1：个人信息
        getBasicInfo() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/getVipInfo/' + this.uid),
                method: 'get',
                // params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.info = data.info
                    this.getCheckResult() // 获取检测记录
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // 接口2：获取检测记录
        getCheckResult() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/getCheckResult'),
                method: 'post',
                data: this.$http.adornData({ uid: this.uid, startTime: this.info.startTime, endTime: this.info.endTime })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)

                    this.usageCount = data.usageCount

                    for (let i = 0; i < this.GeneralInfo.length; i++) {
                        let GeneralInfo = this.GeneralInfo[i]
                        let sequence = ['staticEcg', 'dynamicEcg', 'days', 'duration', 'style']
                        GeneralInfo.number = this.usageCount[sequence[i]]
                    }
                    this.checkListData = []

                    var staticEcgEntities = data.staticEcgEntities
                    for (let i = 0; i < staticEcgEntities.length; i++) {
                        this.$set(staticEcgEntities[i], "type", 'static_ecg_vip') //添加type
                        this.checkListData.push(staticEcgEntities[i])
                    }

                    var dynamicEcgEntities = data.dynamicEcgEntities
                    for (let i = 0; i < dynamicEcgEntities.length; i++) {
                        this.$set(dynamicEcgEntities[i], "type", 'dynamic_ecg_vip') //添加type
                        this.checkListData.push(dynamicEcgEntities[i])
                    }
                    // console.log(this.checkListData)

                    //  列表显示该条数据的总时长
                    for (let i = 0; i < this.checkListData.length; i++) {
                        var totalTime = new Date(this.checkListData[i].duration * 1000)
                        // console.log(totalTime)
                        let d = totalTime.getDate() - 1;
                        let h = totalTime.getHours() - 8;
                        if (h < 0) {
                            d = d - 1
                            h = 24 + h
                        }
                        h = h < 10 ? ('0' + h) : h;

                        let m = totalTime.getMinutes();
                        m = m < 10 ? ('0' + m) : m;
                        let s = totalTime.getSeconds();
                        s = s < 10 ? ('0' + s) : s;
                        if (d != 0) {
                            this.checkListData[i].totalTime = d + '天' + h + '小时' + m + '分'; //显示到分
                        }
                        else if (h == 0) {
                            if (m != 0) {
                                this.checkListData[i].totalTime = m + '分' + s + '秒';  //显示到秒
                            }
                            else {
                                this.checkListData[i].totalTime = s + '秒';  //显示到秒
                            }

                        }
                        else {
                            this.checkListData[i].totalTime = h + '小时' + m + '分' //显示到分
                        }
                    }
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 查看记录
        click2Content(e) {
            console.log(e)
            var recordId
            if (e.type == 'static_ecg_vip') {
                recordId = e.sid
            } else if (e.type == 'dynamic_ecg_vip') {
                recordId = e.did
            } else if (e.type == 'static_ecg_vip') {
                recordId = e.seid
            } else {
                recordId = e.deid
            }
            this.$router.push({
                // name: 'HistorySingo2',
                name: 'vipcheckContent',
                query: {
                    uid: e.uid,
                    recordId: recordId,
                    type: e.type,
                    createTime: e.createTime,
                    duration: e.duration,
                    needReport:this.needReport,
                    // checkDoneTime: e.checkTime,
                    // checkCreateTime: e.createTime,
                    // userDescribe: e.userDescribe,
                    checkStatus: e.status,
                    // DocConclusion: e.conclusion,
                    // DocConId: e.conId,
                }
            })

        },
        // 接口3：插入新的心电报告
        insertNewReport() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/insertNewReport'),
                method: 'post',
                data: this.$http.adornData({
                    uid: this.uid,
                    docId: this.$store.state.doctor_station.docid,
                    realName: this.info.realName,
                    basicDisease: this.info.disease,
                    gender: this.info.gender,
                    startTime: this.info.startTime,
                    createTime: this.info.endTime,
                    age: this.info.age,
                    usageCount: JSON.stringify(this.usageCount)
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.stepIndex = '2' //成功后才能跳转
                    this.tabIndex = '2'
                    this.report = data.report
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 接口4：更新心电报告
        updateReport() {
            this.report.conclusionExplanation = this.conclusionExplanation
            this.report.conclusionCure = this.conclusionCure
            if (this.AbnormalList.length!=0) {
                var arrnew=[]
                this.AbnormalList.forEach(e => {
                    arrnew.push(e.disease)
                })
                // console.log(arrnew)
            }
            this.report.abnormalCondition = JSON.stringify(arrnew)
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/updateReport'),
                method: 'post',
                data: this.$http.adornData(
                    this.report
                )
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.report = data.report
                    let result = JSON.parse(data.report.abnormalCondition)  //数组列表形式，若不是，则加JSON.parse进行转换
                    // result = result.replace("[", "");
                    // result = result.replace("]", "")
                    // result = result.split(',')
                    let uniqueResult = Array.from(new Set(result)) // 去重
                    var arr = []
                    for (let i = 0; i < uniqueResult.length; i++) {
                        let value = uniqueResult[i].toString()
                        if (value) {
                            let obj = {
                                //属性1
                                disease: value
                            };
                            arr.push(obj);
                        }

                    }
                    this.AbnormalList = arr
                    console.log(this.AbnormalList)
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 接口5：查询心电报告
        queryReport() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/queryReport/' + this.report.reportId),
                method: 'get',
                // params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.ViewReport = data.vipReport
                    let result = data.vipReport.abnormalCondition  //数组列表形式，若不是，则加JSON.parse进行转换
                    result = result.replace("[", "");
                    result = result.replace("]", "")
                    result = result.split(',')
                    let uniqueResult = Array.from(new Set(result)) // 去重
                    var arr = []
                    for (let i = 0; i < uniqueResult.length; i++) {
                        let value = uniqueResult[i].toString()
                        if (value) {
                            let obj = {
                                //属性1
                                disease: value
                            };
                            arr.push(obj);
                        }

                    }
                    this.AbnormalList = arr
                    console.log(this.AbnormalList)
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 接口7：查询用户最新一次心电报告
        getLastReport() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/getLastReport'),
                method: 'post',
                params: this.$http.adornParams({
                    uid: this.uid
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.report = data.report
                    this.conclusionCure = this.report.conclusionCure
                    this.conclusionExplanation = this.report.conclusionExplanation

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // 接口8：完成报告推送到公众号
        PushReport() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorVip/monthlyReport'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid, url: 'https://www.xin-gou.com/#/across/ViewMonthlyReport?uid='+this.uid + '&docId=' + this.$store.state.doctor_station.docid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.$message({
                        message: '成功将报告推送至微信公众号！',
                        type: 'success'
                    });
                } else {
                    // this.$message.error(data.msg)
                    this.$message({
                        message: '未关注公众号，请微信搜索【心狗健康】并关注',
                        type: 'warning'
                    });
                }
            })
        },

        // 接口10：医生查询个人签名
        getSignature() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorinfo/getSignature'),
                method: 'get',
                params: this.$http.adornParams({ docId: this.$store.state.doctor_station.docid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.Signature = data.signature
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 添加手写签名
        makeSignature() {
            this.SignatureVisible = true
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            this.Signature = data;
            this.updateSignature()
        },
        // 接口11：医生上传个人签名
        updateSignature() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorinfo/updateSignature'),
                method: 'post',
                data: this.$http.adornData({ docId: this.$store.state.doctor_station.docid, signature: this.Signature })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // step步骤
        FinishPsinfo() {
            this.stepIndex = '1'
            this.tabIndex = '1'
        },
        FinishAnomalyCheck() {
            this.insertNewReport()
        },
        FinishClonclusion() {
            this.updateReport()
            this.stepIndex = '3'
            this.tabIndex = '3'

        },
        FinishView() {
            this.stepIndex = '4'
            this.PushReport()
        },

        // 双击编辑
        cellDblClick(row, column) {
            if (column.property == "disease") {
                this.$set(row, "isEditCell", true);
            }
            this.AbnormalList = this.AbnormalList.filter(item => {
                return item;
            }) //视图刷新
            // console.log(column.property)
            this.$nextTick(() => {
                this.$refs.DiseaseRef.focus(); // 视图出现后使input获取焦点
            })

        },
        // 当编辑框失去焦点
        cellBlur(row, column) {
            row.isEditCell = false;
            this.$set(row, 'isEditCell', false);
        },
        handleClose(done) {
            done();
        },
        deleteRow(index) {
            this.AbnormalList.splice(index, 1)
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.uid = this.$route.query.uid
        this.needReport = this.$route.query.needReport
        if (this.needReport == '2' || this.needReport == 2) {
            this.stepIndex = '2'
            this.tabIndex = '2'
            this.getLastReport()
        }
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getBasicInfo()
        this.getSignature()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  lang="scss" scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}

.item {
    padding: 5px;
    margin: 10px;
    width: 70%;
    height: 50px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;

    .title {
        font-size: 16px;
        height: 100%;
        color: white;
        background-color: #428bca;
        width: 70%;
        display: inline-block;
        border-radius: 4px;

        white-space: nowrap;
        text-overflow: ellipsis;

    }

    .number {
        height: 100%;
        // width: 100%;
        font-size: 20px;
        color: #428bca;
        background-color: #e0e0e0;
        width: 30%;
        display: inline-block;
        border-radius: 4px;
    }
}
</style>